<template>
  <div class="search-box">
    <el-form :model="searchCriteria" class="search-form" label-position="top">
      <el-row :gutter="20">

        <el-col :span="24" :md="12" :lg="5">
          <el-form-item :label="$t('ProcessAfterSale.taskID')">
            <el-input v-model="searchCriteria.taskNumber" ref="taskNumberInput"
                      :placeholder="$t('ProcessAfterSale.taskIDPlaceholder')"
                      clearable></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="24" :md="12" :lg="5">

          <el-form-item :label="$t('ProcessAfterSale.customerName')">
            <el-input v-model="searchCriteria.customerName"
                      :placeholder="$t('ProcessAfterSale.customerNamePlaceholder')"
                      clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" :md="12" :lg="5">
          <el-form-item :label="$t('ProcessAfterSale.country')">
            <el-select v-model="searchCriteria.country" :placeholder="$t('ProcessAfterSale.countryPlaceholder')"
                       clearable
                       label-position="top"
                       filterable
                       style="width: 100%"
            >
              <el-option
                  v-for="item in countryOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" :md="12" :lg="4">
          <el-form-item :label="$t('ProcessAfterSale.brand')">
            <el-input v-model="searchCriteria.brand" :placeholder="$t('ProcessAfterSale.brandPlaceholder')"
                      clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" :md="12" :lg="4">

          <el-form-item :label="$t('ProcessAfterSale.phone')">
            <el-input v-model="searchCriteria.phone" :placeholder="$t('ProcessAfterSale.phonePlaceholder')"
                      clearable></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="24" :md="12" :lg="5">

          <el-form-item :label="$t('ProcessAfterSale.contactPerson')">
            <el-input v-model="searchCriteria.contact" :placeholder="$t('ProcessAfterSale.contactPersonPlaceholder')"
                      clearable></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="24" :md="12" :lg="5">
          <el-form-item :label="$t('ProcessAfterSale.processingProgress')">
            <el-select v-model="searchCriteria.progress"
                       :placeholder="$t('ProcessAfterSale.processingProgressPlaceholder')"
                       clearable filterable
                       style="width: 100%"
            >
              <el-option :label="$t('ProcessAfterSale.unprocessed')" :value="0"></el-option>
              <el-option :label="$t('ProcessAfterSale.processing')" :value="1"></el-option>
              <el-option :label="$t('ProcessAfterSale.completed')" :value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="24" :md="12" :lg="5">
          <el-form-item :label="$t('ProcessAfterSale.submitTime')">
            <el-date-picker v-model="searchCriteria.submissionTime" type="datetimerange" :clearable="true"
                            :start-placeholder="$t('ProcessAfterSale.startDatePlaceholder')"
                            :end-placeholder="$t('ProcessAfterSale.endDatePlaceholder')"
                            aria-pressed="false"
            />
          </el-form-item>
        </el-col>


        <el-col :span="24" :md="12" :lg="4">

          <el-form-item class="form-item-button" label="&nbsp;">
            <el-button class="button-background-color" @click="search"> {{ $t('ProcessAfterSale.search') }}</el-button>
            <el-button @click="reset" class="custom-reset-button">{{ $t('ProcessAfterSale.reset') }}</el-button>
          </el-form-item>
        </el-col>

      </el-row>
    </el-form>
  </div>
</template>

<script>

import dialogMixin from "@/mixins/dialogMixin";
import countryMixin from "@/mixins/countryMixin";

export default {
  name: 'SearchBox',
  mixins: [dialogMixin,countryMixin],
  data() {
    return {
      searchCriteria: {
        taskNumber: '',
        customerName: '',
        country: '',
        brand: '',
        phone: '',
        contact: '',
        progress: '',
        submissionTime: '',
      },
      selectWidth: 'auto', // 默认宽度或你的选择
    };
  },
  beforeMount() {
    this.$nextTick(() => {
      // 在组件渲染前，找到对应的 el-input__inner 元素，然后修改样式
      const inputInner = this.$el.querySelector('.el-input__inner');
      if (inputInner) {
        inputInner.style.borderColor = 'rgb(0, 154, 68)';
        inputInner.style.borderWidth = '2px';
        inputInner.style.boxShadow = 'none';
      }
    });
  },
  watch: {
    searchCriteria: {
      deep: true,
      handler() {
        this.search();
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.selectWidth = `${this.$refs.taskNumberInput.$el.offsetWidth}px`;
    });
    this.fetchCountryOptions();

  },
  methods: {

    search() {
      let searchCondition = {};

      if (this.searchCriteria.submissionTime && this.searchCriteria.submissionTime.length === 2) {
        const [start, end] = this.searchCriteria.submissionTime;
        searchCondition["commit_at-begin"] = this.formatDate(null, null, start); // 或者格式化为其他后端期望的格式
        searchCondition["commit_at-end"] = this.formatDate(null, null, end); // 或者格式化为其他后端期望的格式
      }

      if (this.searchCriteria.taskNumber) {
        searchCondition["serial_number-like"] = this.searchCriteria.taskNumber;
      }

      if (this.searchCriteria.customerName) {
        searchCondition["customer_name-like"] = this.searchCriteria.customerName;
      }

      if (this.searchCriteria.country) {
        searchCondition["country_id-eq"] = this.searchCriteria.country;
      }

      if (this.searchCriteria.brand) {
        searchCondition["brand-like"] = this.searchCriteria.brand;
      }

      if (this.searchCriteria.phone) {
        searchCondition["phone-like"] = this.searchCriteria.phone;
      }

      if (this.searchCriteria.contact) {
        searchCondition["contact-like"] = this.searchCriteria.contact;
      }
      if (this.searchCriteria.progress || this.searchCriteria.progress === 0) {
        searchCondition["process_status-eq"] = this.searchCriteria.progress;
      }
      // 执行搜索逻辑，并将 this.searchCriteria 传递给父组件或调用 API
      this.$emit('search', searchCondition);
    },

    reset() {
      // 重置搜索条件
      this.searchCriteria = {
        taskNumber: '',
        customerName: '',
        country: '',
        brand: '',
        phone: '',
        contact: '',
        progress: '',
        submissionTime: '',
        endTime: '',
      };
      this.search();
    },


  },
};
</script>

<style scoped>

.search-box {
  margin-top: 20px;

  margin-left: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  border: 1px solid rgb(236, 239, 239);
  background-color: rgb(255, 255, 255);
  border-radius: 2px;
  margin-right: 20px;
  padding: 15px 0 20px 25px;
}

.search-form {
  display: flex;
  flex-wrap: wrap;
}

/*.responsive-item {
  margin-bottom: 10px;
  display: inline-block;
  width: 20%;
}*/

@media (max-width: 768px) {
  /*.responsive-item {
    width: 100%;
  }*/
}

@media (min-width: 769px) and (max-width: 1024px) {
  /*.responsive-item {
    width: 50%;
  }*/
}

/* @media (max-width:3000px) {
  .responsive-item {
    width: 30%;
  }
} */
</style>
