import { request } from "@/utils/http";


export default {
    firmwareList: (params = {}) => request.get('/firmware-list', { params }),
    productDetail: (params = {}) => request.get('/product-models', { params }),
    addUpgradeRecords: (data) => request.post('/upgrade-records', data),
    pluginVersion: () => request.get('/plugins/last'),
    hardwareProtocol: () => request.get('/hardware-protocol'),
    addConnectRecord: (data) => request.post('/connect-record',data),
    productNotify: (data) => request.post('/product-notify',data),
}