<template>
  <div v-loading="loading" element-loading-text="Loading...">
    <el-row class="text-info">
      <el-col :span="6" :md="3" :lg="6" v-for="(field, index) in fields" :key="index" class="text-info-col">
        <el-form :model="localInfoData[index]" ref="textInfo">
          <el-form-item :label="field.label" class="text-info-form-item">
            <el-input
                v-if="localInfoData[index] && localInfoData[index].hasOwnProperty(field.model)"
                v-model="localInfoData[index][field.model]"
                disabled
                class="small-input"
            ></el-input>
            <div v-else>Value not available</div>
          </el-form-item>

        </el-form>
      </el-col>
    </el-row>
  </div>
</template>


<script>
export default {
  props: {
    infoData: {
      type: Array,
      required: true,
    },
    loading: Boolean  // 接收 loading 状态
  },
  data() {

    return {
      localInfoData: this.infoData,
      fields: [
        {label: this.$t('ProcessAfterSale.customerName'), model: 'customer_name'},
        {label: this.$t('ProcessAfterSale.country'), model: 'country'},
        {label: this.$t('ProcessAfterSale.address'), model: 'address'},
        {label: this.$t('ProcessAfterSale.brandEBike'), model: 'brand'},
        {label: this.$t('ProcessAfterSale.phone'), model: 'phone'},
        {label: this.$t('ProcessAfterSale.pickupAddress'), model: 'delivery_address'},
        {label: this.$t('ProcessAfterSale.email'), model: 'email'},
        {label: this.$t('ProcessAfterSale.contactPerson'), model: 'contact_person'},
      ],
    };
  },
  watch: {
    infoData(newInfoData) {
      this.localInfoData = newInfoData;
    },
  },
};
</script>

<style scoped>
.text-info-col {
  padding-left: 10px;
  margin-bottom: 5px;
}

.small-input {
  width: 80%;
}

.text-info {
  margin-top: 20px;
}
</style>
