<template>
  <div class="data-table" v-loading="loading" element-loading-text="Loading...">
    <el-table :header-cell-style="{background:'rgb(250, 250, 250)'}" :data="tableData"
              class="table-height"
              style="width: 100%;overflow-x: auto"
              :row-style="{ height: '65px' }"
              @select="handleSelectionChange"
              @select-all="handleSelectAll"
              :row-class-name="rowClassName"
    >

      <el-table-column type="selection" v-if="!showOperation"></el-table-column>

      <el-table-column prop="taskNumber" :label="$t('ProcessAfterSale.taskID')"></el-table-column>
      <el-table-column prop="customerName" :label="$t('ProcessAfterSale.customerName')"
      ></el-table-column>
      <el-table-column prop="country" :label="$t('ProcessAfterSale.country')"></el-table-column>
      <el-table-column prop="address" :label="$t('ProcessAfterSale.address')"></el-table-column>
      <el-table-column prop="brand" :label="$t('ProcessAfterSale.brandEBike')"></el-table-column>
      <el-table-column prop="phone" :label="$t('ProcessAfterSale.phone')"></el-table-column>
      <el-table-column prop="deliveryAddress" :label="$t('ProcessAfterSale.pickupAddress')"
      ></el-table-column>
      <el-table-column prop="email" :label="$t('ProcessAfterSale.email')"></el-table-column>
      <el-table-column prop="contact" :label="$t('ProcessAfterSale.contactPerson')"
      ></el-table-column>
      <el-table-column prop="progress" :label="$t('ProcessAfterSale.processingProgress')"
      ></el-table-column>
      <el-table-column prop="submissionTime" :label="$t('ProcessAfterSale.submitTime')"
      ></el-table-column>
      <el-table-column v-if="showOperation" :label="$t('ProcessAfterSale.operation')" fixed="right" width="160">
        <template v-slot="scope">
          <el-button link @click="edit(scope.row)"><span
              class="link-text-style">{{ $t('ProcessAfterSale.details') }}</span></el-button>
          <el-popover
              ref="popover"
              placement="bottom"
              trigger="hover"
          >
            <template #reference>
              <el-button class="link-text-style" link><span
                  class="link-text-style">{{ $t('ProcessAfterSale.status') }}</span></el-button>
            </template>
            <template #default>
              <ul class="status-options">
                <li class="li-margin" @click="setStatus(scope.row,0)">{{ $t('ProcessAfterSale.unprocessed') }}</li>
                <li class="li-margin" @click="setStatus(scope.row,1)">{{ $t('ProcessAfterSale.processing') }}</li>
                <li class="li-margin" @click="setStatus(scope.row,2)">{{ $t('ProcessAfterSale.completed') }}</li>
              </ul>
            </template>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {defineComponent, reactive} from 'vue';
import {useRouter} from 'vue-router';
import {updateAfterSalesApply} from "@/api/api";
import resizeMixin from "@/mixins/resizeMixin"; // 使用 useRouter

export default defineComponent({
  mixins: [resizeMixin],

  setup() {
    const router = useRouter(); // 使用 useRouter 获取路由实例
    // 编辑按钮的点击事件处理函数
    const edit = (row) => {
      if (row.progress) {
        const DetailsPagePath = `/DetailsPage/${row.after_sales_id}`;
        // 使用路由导航到编辑页面
        router.push(DetailsPagePath);
      }
    };
    // 创建一个响应式的selectedRows数组
    const state = reactive({
      selectedRows: []
    });
    return {
      ...state, // 展开state对象，使得selectedRows在模板中可用
      edit, // 返回编辑函数
    };
  },
  name: 'DataTable',
  props: {
    tableData: {},
    loading: Boolean,
    loadData: {
      type: Function,
    },
    showOperation: {
      type: Boolean,
      default: true
    },
  },
  methods: {

    //修改处理进度的状态
    async setStatus(row, processing_progress) {
      const formData = {
        process_status: processing_progress,
      };
      try {
        await updateAfterSalesApply(row.after_sales_id, formData);
        // 处理成功响应
        this.$message.success(this.$t('userManage.modifySuccess'));

        // 这里你可能需要刷新表格或更新相应的状态
        await this.loadData(); // 假设你有一个方法来重新获取并刷新FAQ列表
      } catch (error) {
        // 处理错误
        console.error('Error updating status:', error);
      }
    },
    // 设置行的类名
    rowClassName({row}) {
      return this.selectedRows.some(selectedRow => selectedRow.after_sales_id === row.after_sales_id) ? 'row-selected' : '';
    },
    // 选中状态变化处理
    handleSelectionChange(selection) {
      // 获取所有勾选行的 after_sales_id
      const selectedIds = selection.map(item => item.after_sales_id);
      this.selectedRows = selection;
      this.$emit('selectedAftersalesIds', selectedIds);
    },
    handleSelectAll(selection) {
      const selectedIds = selection.map(item => item.after_sales_id);
      this.selectedRows = selection;
      this.$emit('selectedAftersalesIds', selectedIds);
    },

  },
});
</script>
<style scoped>

.data-table :deep( th) {
  background-color: rgb(250, 250, 250);
}

.data-table {
  margin: 10px 20px; /* 设置默认的左右间隔 */

  @media (max-width: 768px) {
    margin: 5px 10px; /* 在小屏幕下减小间隔 */
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    margin: 15px 30px; /* 在中等屏幕下增加间隔 */
  }

  /* 添加其他屏幕尺寸的媒体查询和 margin 设置 */
}

.status-options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.status-options li {
  color: rgb(0, 154, 68);
  cursor: pointer;
}

.status-options li:hover {
  background-color: rgb(245, 245, 245);
}

.li-margin {
  text-align: center;
  height: 40px;
  vertical-align: center;
}

/* 对于小屏幕设备（例如手机） */
@media (max-width: 768px) {
  .data-table {
    font-size: 14px;
    /* 调整字体大小 */
  }

}

/* 对于中等屏幕设备（例如平板电脑） */
@media (min-width: 769px) and (max-width: 1024px) {
  .data-table {
    font-size: 16px;
    /* 调整字体大小 */
  }
}

/* 你可以继续添加其他屏幕尺寸的规则 */
</style>