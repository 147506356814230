<template>
  <hr class="separator " style="margin-top: 1px"/>

  <div>
    <div class="custom-text">
      {{ $t('ProcessAfterSale.processAfterSale') }}
    </div>
    <hr class="separator"/>
  </div>
  <div>
    <!-- TextInfo 组件 -->
    <TextInfo :infoData="infoData" :loading="loading"/>

    <div class="submit-time">{{ $t('ProcessAfterSale.submitTime') }}： {{ submission_time_data }}</div>

    <TableShow :tableData="tableData" :loadData="loadData" :loading="loading" :showOperationColumn="false"/>

    <!-- Pagination 组件 -->
    <PaginationBar :total-records="totalRecords" :current-page="currentPage" :page-size="pageSize"
                   @page-change="handlePageChange" @page-size-change="handlePageSizeChange"/>
    <div class="form-actions">
      <el-button @click="goBack" class="custom-reset-button"> {{ $t('ProcessAfterSale.return') }}</el-button>
    </div>
  </div>
</template>

<script>
import TextInfo from "./DetailInfoComponents/TextInfo"; // 导入 TextInfo 组件
import PaginationBar from "@/components/PaginationBar";
import {getAfterSalesDetails, showAfterSalesData} from "@/api/api";
import TableShow from "@/views/AfterSales/editTask/components/TableShow.vue"; // 导入 Pagination 组件

export default {
  components: {
    TableShow,
    TextInfo,
    PaginationBar,
  },
  data() {
    return {
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示条数
      totalRecords: 0, // 总记录数

      contacts: [
        // 初始化联系人信息
        {
          name: "",
          phone: "",
          email: "",
          address: "",
        },
      ],
      infoData: [], // 初始化为一个空对象
      tableData: [], // 初始化为一个空数组
      submission_time_data: '',
      loading: false, // 加载状态
    };
  },
  async created() {
    const afterSalesId = this.$route.params.taskId;
    this.infoData = []; // 添加这一行
    this.tableData = []; // 添加这一行
    await this.loadData(afterSalesId);
  },
  props: {
    taskId: {
      type: String, // 你的数据类型可能不是字符串
    },
  },
  methods: {
    goBack() {
      // 使用 Vue Router 返回上一个页面
      this.$router.go(-1); // 或者使用 this.$router.back();
    },
    // 处理分页变化事件
    handlePageChange(newPage) {
      this.currentPage = newPage;
    },
    // 处理每页显示条数变化事件
    handlePageSizeChange(newPageSize) {
      this.pageSize = newPageSize;
    },
    async loadData(afterSalesId) {
      this.loading = true; // 开始加载
      try {
        const query = {'after_sale_id-eq': afterSalesId};
        // 调用 fetchData 函数获取数据
        const data = await showAfterSalesData(afterSalesId);
        this.infoData = [
          {customer_name: data.data.customer_name},
          {country: data.data.country_name},
          {address: data.data.address},
          {brand: data.data.brand},
          {phone: data.data.phone},
          {delivery_address: data.data.pick_up_address},
          {email: data.data.email},
          {contact_person: data.data.contact}
        ];

        const page = this.currentPage;
        const pageSize = this.pageSize;
        const responseTableData = await getAfterSalesDetails(page, pageSize, query);

        this.tableData = responseTableData.data.map(item => ({
          after_sales_id: item.after_sale_id,
          motorAppearance: item.appearance_status,
          defectDescription: item.description,
          defectCategory: item.error_type,
          id: item.id,
          defectImages: item.image,
          image_url: item.image_url,
          invoice: item.invoice,
          invoice_url: item.invoice_url,
          materialCode: item.material_code,
          SN: item.sn, // 根据值映射中文名称
          SNLabel: item.sn_label,
          sn_label_url: item.sn_label_url,
          defectVideos: item.video,
          video_url: item.video_url,
        }));
        this.totalRecords = responseTableData.meta.total;
        this.currentPage = responseTableData.meta.current_page; // 更新当前页（如果后端返回当前页信息）
        this.pageSize = parseInt(responseTableData.meta.per_page, 10); // 更新每页记录数
        this.submission_time_data = data.data.commit_at;
      } catch (error) {
        // 处理错误
        console.error('An error occurred during data loading:', error);
      } finally {
        this.loading = false; // 加载结束
      }
    },
  },
};
</script>

<style scoped>
.submit-time {
  margin:0 0 40px 20px;
  text-align: left;
  font-size: 14px;
}

.form-actions {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.custom-text {
  text-align: left;
  font-size: 14px;
  margin-bottom: 15px;
  margin-top: 15px;
}

.separator {
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  /* 更透明的横线 */
  margin: 8px 0;
  /* 调整分隔线上下的间距 */
  width: 100%;
  /* 横线占满整个宽度 */
}
</style>
