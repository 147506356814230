<template>

  <div class="button-component-style">
    <el-button class="button-background-color" @click="handleExportClick" v-loading="isExportLoading">
      {{ $t('ProcessAfterSale.export') }}
    </el-button>

    <el-button class=" custom-reset-button" @click="openBulkEditDialog"> {{ $t('ProcessAfterSale.batchModify') }}
    </el-button>

    <!-- 使用 el-dialog 实现弹框 -->
    <el-dialog v-model="bulkEditDialogVisible" width="1500px" @open="disableScroll" @close="enableScroll">
      <!-- 弹框内容，可以包含你的表单或编辑项 -->
      <div>
        <SearchBox @search="handleSearch"/>

        <DataTable :tableData="tableData" @selectedAftersalesIds="handleSelectedAftersalesIds" :loadData="loadData" :showOperation="false" :loading="loading"/>

        <PaginationBar :total-records="totalRecords" :current-page="currentPage" :page-size="pageSize"
                       @page-change="handlePageChange" @page-size-change="handlePageSizeChange"/>
      </div>
      <span class="dialog-footer" style="margin-top: 50px;">
        <el-dropdown v-model="isDropdownVisible" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
          <el-button class="button-background-color">{{ $t('ProcessAfterSale.modifyStatus') }}</el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="batchUpdateStatus(0)">{{
                  $t('ProcessAfterSale.unprocessed')
                }}</el-dropdown-item>
              <el-dropdown-item @click="batchUpdateStatus(1)">{{ $t('ProcessAfterSale.processing') }}</el-dropdown-item>
              <el-dropdown-item @click="batchUpdateStatus(2)">{{ $t('ProcessAfterSale.completed') }}</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </span>

    </el-dialog>
  </div>
</template>

<script>
import SearchBox from '@/views/ServiceCenter/components/SearchBox.vue';
import PaginationBar from '@/components/PaginationBar.vue';
import {getAfterSales, updateAfterSalesApplyIds} from "@/api/api";
import dialogMixin from "@/mixins/dialogMixin";
import DataTable from "@/views/ServiceCenter/components/DataTable.vue";

export default {
  name: 'BulkEditButton',
  components: {
    DataTable,
    SearchBox,
    PaginationBar,
  },
  data() {
    return {
      bulkEditDialogVisible: false, // 控制弹框显示与隐藏
      tableData: [], // Your table data
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示条数
      totalRecords: 0, // 总记录数
      showStatusDropdown: false,
      isSearching: false,
      searchParams: null,
      selectedAftersalesIds: [],
      isDropdownVisible: false,
      loading: false, // 加载状态
      isExportLoading: false, // 导出时的加载状态
    };
  },
  props: {
    queryParameters: {
      type: Object,
      required: true,
    }
  },
  created() {
    this.loadData();
  },
  watch : {
    '$i18n.locale'() {
      this.handleSearch(this.searchParams);
    },
  },
  mixins: [dialogMixin],
  methods: {
    openBulkEditDialog() {
      this.bulkEditDialogVisible = true; // 打开弹框
    },

    // 处理分页变化事件
    async handlePageChange(newPage) {
      // 异步操作的处理逻辑
      try {
        this.currentPage = newPage;
        // 根据新的页码发送请求获取数据，更新tableData
        if (this.isSearching) {
          await this.handleSearch(this.searchParams);
        } else {
          await this.loadData();
        }
      } catch (error) {
        console.error('Error:', error);
      }
    },
    // 处理每页显示条数变化事件
    async handlePageSizeChange(newPageSize) {

      // 异步操作的处理逻辑
      try {
        this.pageSize = typeof newPageSize === 'string' ? parseInt(newPageSize, 10) : newPageSize;
        // 根据新的页码发送请求获取数据，更新tableData
        if (this.isSearching) {
          await this.handleSearch(this.searchParams);
        } else {
          await this.loadData();
        }
      } catch (error) {
        console.error('Error:', error);
      }
    },
    async handleSearch(searchParams) {
      const page = this.currentPage;
      const pageSize = this.pageSize;
      this.isSearching = true;
      this.searchParams = searchParams;
      this.loading = true; // 开始加载
      try {
        const response = await getAfterSales(page, pageSize, searchParams);
        // 在这里进行数据转换
        this.tableData = response.data.map(item => ({
          after_sales_id: item.after_sales_id,
          taskNumber: item.serial_number,
          customerName: item.customer_name, // 根据值映射中文名称
          country: this.$t(`countries.${item.country_code}`),
          address: item.address,
          brand: item.brand,
          phone: item.phone,
          deliveryAddress: item.pick_up_address,
          email: item.email,
          contact: item.contact,
          progress: this.mapProcessingProgress(item.process_status),
          submissionTime: item.commit_at,
        }));

        this.totalRecords = response.meta.total;
        this.currentPage = response.meta.current_page;
        this.pageSize = parseInt(response.meta.per_page);
      } catch (error) {
        console.error('Error:', error);
      } finally {
        this.loading = false; // 加载结束
      }
    },
    async loadData() {
      this.isSearching = false;
      const page = this.currentPage;
      const pageSize = this.pageSize;
      this.loading = true; // 开始加载
      try {
        const data = await getAfterSales(page, pageSize);
        // 将从服务器获取的数据进行字段重命名
        this.tableData = data.data.map(item => ({
          after_sales_id: item.id,
          taskNumber: item.serial_number,
          customerName: item.customer_name, // 根据值映射中文名称
          country: this.$t(`countries.${item.country_code}`),
          country_id: item.country_id,
          address: item.address,
          brand: item.brand,
          phone: item.phone,
          deliveryAddress: item.pick_up_address,
          email: item.email,
          contact: item.contact,
          submissionTime: item.commit_at,
          submitProgress: item.commit_status === 0 ? this.$t('ApplyAfterSales.notSubmitted') : this.$t('ApplyAfterSales.submitted'),
          progress: this.mapProcessingProgress(item.process_status),
        }));
        this.totalRecords = data.meta.total;
        this.currentPage = data.meta.current_page;
        this.pageSize = typeof data.meta.per_page === 'string' ? parseInt(data.meta.per_page, 10) : data.meta.per_page;
      } catch (error) {
        console.error('Error:', error);
      } finally {
        this.loading = false; // 加载结束
      }

    },
    handleStatusChange(status) {
      // 修改成功后可以弹出提示
      this.$message.success(`${this.$t('ProcessAfterSale.statusChanged')}${status}`);
    },
    handleSelectedAftersalesIds(selectedAftersalesIds) {
      this.selectedAftersalesIds = selectedAftersalesIds;
    },
    // 修改状态按钮点击事件
    async batchUpdateStatus(status) {
      try {
        // 判断是否勾选了数据
        if (this.selectedAftersalesIds.length === 0) {
          this.$message.error(this.$t('ProcessAfterSale.pleaseSelectData'));
          return;
        }

        // 组合数据
        const combinedData = {
          ids: this.selectedAftersalesIds,
          process_status: status, // 这里只是示例，你需要根据实际情况获取
        };
        // 调用后端接口进行状态更新
        await updateAfterSalesApplyIds(combinedData);

        this.$message.success(this.$t('ProcessAfterSale.bulkUpdateStatusSuccess'));
        // 触发自定义事件通知子组件更新数据
        await this.loadData();

      } catch (error) {
        // 处理错误
        console.error('Error updating status:', error);
      } finally {
        // 清空选中的aftersalesids
        this.selectedAftersalesIds = [];
      }
    },
    handleMouseEnter() {
      this.isDropdownVisible = true;
    },
    handleMouseLeave() {
      this.isDropdownVisible = false;
    },

    handleExportClick() {
      this.isExportLoading = true;
      this.$emit('export',this.setExportLoading);
    },
    setExportLoading(loading) {
      this.isExportLoading = loading;
    }
  }
};
</script>

<style scoped>

.button-component-style {
  display: flex;
  margin-left: 20px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.el-dialog__body {
  overflow: hidden; /* 确保弹框内容不会超出边界 */
}
</style>
